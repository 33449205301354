<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    max-width="600px"
    @click:outside="onClose"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        block
        color="secondary"
        @click="onShowDialog"
        v-on="on"
        data-test="activateButton"
        :class="editMode ? '' : 'ml-2'"
        :data-cy="editMode ? `company-profile-edit-button-${company.id}` : 'company-profile-create-button'"
      >
        {{ editMode ? "Edit Company Profile" : "Create New Company" }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="mb-5">
        <span class="headline font-weight-bold primary--text">{{
          editMode ? "Edit Company Profile" : "Create New Company"
        }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row dense>
            <small class="red--text">* indicates required field</small>
          </v-row>
          <v-form lazy-validation ref="form">
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  label="Name *"
                  name="name"
                  type="text"
                  autocomplete="off"
                  v-model="formName"
                  :rules="rules.requireCheck"
                  :data-cy="editMode ? `company-profile-modal-name${company.id}` : 'company-profile-modal-name'"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <gerber-file-chip
                  v-if="editMode && ndaFileContainer"
                  :editMode="editMode"
                  :fileList="ndaFileContainer"
                  :allowDelete="true"
                  @removeFile="removeFile"
                />
                <v-file-input
                  show-size
                  counter
                  label="NDA File"
                  v-model="formNdaFile"
                  accept=".pdf"
                  hint="File type: .PDF"
                  persistent-hint
                  :data-cy="editMode ? `company-profile-modal-nda-file${company.id}` : 'company-profile-modal-nda-file'"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="warning">
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-divider />
        <v-row class="ma-2">
          <v-btn color="warning" @click="onClose" data-test="cancelButton" :data-cy="editMode ? `company-profile-modal-cancel-button${company.id}` : 'company-profile-modal-cancel-button'"
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="onSave" data-test="saveButton" :data-cy="editMode ? `company-profile-modal-save-button${company.id}` : 'company-profile-modal-save-button'"
            >Save</v-btn
          >
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "CompanyFormModal",
  props: {
    company: {
      type: Object,
      default: null,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    GerberFileChip: () =>
      import("@/modules/dutconfigs/components/GerberFileChip.vue"),
  },
  data() {
    return {
      formName: null,
      formBillingAddress: null,
      formShippingSame: false,
      formShippingAddress: null,
      formNdaFile: null,
      dialog: false,
      rules: {
        requireCheck: [(v) => !!v || "Input is required"],
      },
    };
  },
  computed: {
    ndaFileContainer() {
      return this.company.nda
        ? [
            {
              url: this.company.nda,
              file: this.company.nda.split("?")[0].split("/").pop(),
            },
          ]
        : null;
    },
  },
  methods: {
    onShowDialog() {
      this.formName = this.editMode ? this.company.name : null;
    },
    onClose() {
      this.$refs.form.reset();
      this.dialog = false;
    },
    onSave() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let inputData = new FormData();
      if (this.formName) {
        inputData.append("name", this.formName);
      }
      if (this.formNdaFile) {
        inputData.append("nda", this.formNdaFile);
      }
      const actionPayload = {
        editMode: this.editMode,
        id: this.editMode ? this.company.id : null,
        data: inputData,
      };
      this.$emit("saveData", actionPayload);
      this.$refs.form.reset();
      this.dialog = false;
    },
    removeFile() {
      let ndaData = new FormData();
      ndaData.append("nda", new File([], ""));
      const actionPayload = {
        editMode: this.editMode,
        id: this.company.id,
        data: ndaData,
      };
      this.$emit("saveData", actionPayload);
    },
  },
};
</script>
